import { PageContainer, PageHeader } from '@app/shared/components';
import { ILocationComponentProps } from '@app/shared/routes';
import { useAuthContext } from '@contexts/auth';
import { useGetBuilding } from '@hooks/buildings';
import { useGetAllFloorplans } from '@hooks/floorplans/useGetAllFloorplans';
import { useFloorplans } from '@stores/floorplansStore';
import React, { ReactElement } from 'react';
import { Switch } from 'react-router-dom';

import FloorplansForm from './floorplans-form/FloorplansForm';
import {
  CreateFloorplansFormLocation,
  EditFloorplansFormLocation
} from './floorplans-form/routes/floorplansFormLocation';
import FloorplansList from './floorplans-list/FloorplansList';
import FloorplansToolbar from './floorplans-toolbar/FloorplansToolbar';
import { FloorplansLocationParams } from './routes/floorplansLocation';

interface FloorplansProps
  extends ILocationComponentProps<FloorplansLocationParams> {}

const Floorplans = ({
  match: {
    params: { venueId, buildingId }
  }
}: FloorplansProps): ReactElement => {
  const { user } = useAuthContext();
  const isAdmin = user?.rol === 'admin';
  const filter = useFloorplans((s) => s.filter);

  const { data: building } = useGetBuilding(venueId, buildingId);
  const { data: floorplans = [], isFetching } = useGetAllFloorplans(
    venueId,
    buildingId,
    {
      archived: filter.archived,
      unpublished: isAdmin ? filter.unpublish : filter.unpublish = false,
      closed: filter.closed
    }
  );

  return (
    <PageContainer isFetching={isFetching}>
      <PageHeader>
        <FloorplansToolbar
          venueId={venueId}
          venueName={building?.venueName || ''}
          buildingId={buildingId}
          buildingName={building?.name || ''}
          canDoActions={isAdmin}
        />
      </PageHeader>
      <FloorplansList
        venueId={venueId}
        buildingId={buildingId}
        floorplans={floorplans}
        canDoActions={isAdmin}
      />
      <Switch>
        {CreateFloorplansFormLocation.toRoute({
          render: (props) => <FloorplansForm {...props} mode='create' />
        })}
        {EditFloorplansFormLocation.toRoute({
          render: (props) => <FloorplansForm {...props} mode='edit' />
        })}
      </Switch>
    </PageContainer>
  );
};

export default Floorplans;

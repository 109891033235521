import {
  ButtonSecondary,
  FilterButtons,
  FilterButtonsItem
} from '@app/shared/components';
import { useAuthContext } from '@contexts/auth';
import { Venue } from '@domain/venues';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BuildingsLocation } from '../../routes/buildingsLocation';
import { VenuesListLocation } from './venues-list/routes/venuesListLocation';
import { VenuesWrapper } from './venues-wrapper/VenuesWrapper';

const INITIAL_ACTIVE_INDEX = 0;

const getVenueIndex = (
  venues: Venue[],
  venueId: string | undefined
): number => {
  return venueId
    ? venues.findIndex((x) => x.id === venueId)
    : INITIAL_ACTIVE_INDEX;
};

interface VenuesProps {
  venues: Venue[];
  venueId: string;
}

const Venues = ({ venues, venueId }: VenuesProps): ReactElement => {
  const { t } = useTranslation('venues');

  const selectedVenueIndex = useMemo(() => getVenueIndex(venues, venueId), [
    venues,
    venueId
  ]);

  const history = useHistory();

  const { user } = useAuthContext();
  const isAdmin = user?.rol === 'admin';

  const venueButtons: FilterButtonsItem[] = venues.map((v, index) => ({
    label: v.name,
    active: index === selectedVenueIndex,
    onClick: () => history.push(BuildingsLocation.toUrl({ venueId: v.id }))
  }));

  return (
    <VenuesWrapper>
      <FilterButtons buttons={venueButtons} noBorder />
      {isAdmin ? 
        <ButtonSecondary
        iconName='settings'
        label={t('toolbar.venues')}
        onClick={() => history.push(VenuesListLocation.toUrl({ venueId }))}
      /> : null
      }
    </VenuesWrapper>
  );
};

export default Venues;

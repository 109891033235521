import { Grid, NoResults } from '@app/shared/components';
import { Building } from '@domain/buildings';
import { useLoadImage } from '@hooks/useLoadImage';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import BuildingsCard from './buildings-card/BuildingsCard';

interface BuildingsListProps {
  buildings: Building[];
  canDoActions: boolean;
}

const BuildingsList = ({ buildings, canDoActions }: BuildingsListProps): ReactElement => {
  const { t } = useTranslation('buildings');

  const { getImage } = useLoadImage();

  const itemTemplate = useCallback(
    (building: Building) => <BuildingsCard building={building} canDoActions={canDoActions}/>,
    []
  );

  return (
    <>
      {buildings && buildings.length ? (
        <Grid items={buildings ?? []} itemTemplate={itemTemplate} />
      ) : (
        <NoResults
          title={t('noResults.title')}
          description={t('noResults.description')}
          image={getImage('NoBuildings')}
        />
      )}
    </>
  );
};

export default BuildingsList;

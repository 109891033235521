import {
  ButtonPrimary,
  ButtonsContainer,
  ButtonSecondary,
  DetailsFormContentAlt,
  FormFooter,
  Input,
  Select
} from '@app/shared/components';
import { UserSearcher } from '@app/shared/components/form/UserSearcher';
import {
  BookingFormModel,
  bookingFormModelSchema
} from '@domain/bookings/models/shared/BookingFormModel';
import { EmployeeTypeEnum, employeeTypes } from '@domain/employees';
import { yupResolver } from '@hookform/resolvers';
import { useGetShift } from '@hooks/buildings/useGetShift';
import { useUserProfile } from '@stores/userProfileStore';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface FloorplanNewBookingProps {
  buildingId: string;
  onSubmit: (data: BookingFormModel) => void;
  onClose: () => void;
  isAdmin: boolean;
}

const FloorplanNewBooking = ({
  buildingId,
  onSubmit,
  onClose,
  isAdmin
}: FloorplanNewBookingProps): ReactElement => {
  const { t } = useTranslation('workplaces');
  const [employeeType, setEmployeeType] = useState<EmployeeTypeEnum | undefined>(!isAdmin ? EmployeeTypeEnum.Internal : undefined);

  const { data: shifts, isLoading: isLoadingShifts } = useGetShift({
    buildingId,
    suspense: false
  });

  const {
    control,
    errors,
    formState,
    setValue,
    register,
    handleSubmit,
    reset
  } = useForm<BookingFormModel>({
    resolver: yupResolver(bookingFormModelSchema),
    mode: 'all'
  });

  useEffect(() => {
    if (formState.isSubmitted) {
      reset(
        {
          shiftTypeId: '',
          employeeType: undefined,
          bookedUser: { id: '', name: '', email: '' },
          externalUserMail: ''
        },
        { isValid: false }
      );
    }
  }, [formState.isSubmitted]);

  const { userProfile } = useUserProfile();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DetailsFormContentAlt>
        <Controller
          name='shiftTypeId'
          control={control}
          defaultValue={null}
          render={({ value, onChange }) => (
            <Select
              label={t('fieldNames.shift')}
              items={shifts ?? []}
              item={(shifts ?? []).find((s) => s.id === value)}
              getItemLabel={(item) =>
                item?.id
                  ? t(`fieldNames.shiftType.${item.id}`)
                  : t('fieldNames.shiftPlaceholder')
              }
              placeholder={t('fieldNames.shiftPlaceholder')}
              getItemValue={(item) => item?.id}
              searchable={false}
              error={errors?.shiftTypeId}
              isLoading={isLoadingShifts}
              onChange={(item) => onChange(item?.id)}              
            />
          )}
        />
        <Controller
          name='employeeType'
          control={control}
          defaultValue={employeeType}
          render={({ value, onChange }) => (
            <Select
              label={t('fieldNames.employeeTypeLabel')}
              items={employeeTypes}
              item={employeeTypes.find((type) => type.id === value)}
              getItemLabel={(item) =>
                item?.id
                  ? t(`fieldNames.employeeType.${item.id}`)
                  : t('fieldNames.employeeTypePlaceholder')
              }
              placeholder={t('fieldNames.employeeTypePlaceholder')}
              getItemValue={(item) => item?.id}
              searchable={false}
              error={errors?.employeeType}
              onChange={(item) => {
                setEmployeeType(item?.id);
                onChange(item?.id);
              }}
              disabled={!isAdmin}
            />
          )}
        />
        {employeeType === EmployeeTypeEnum.Internal ? (
          <>
            <Controller
              name='bookedUser'
              control={control}
              defaultValue={isAdmin ? null : {name: userProfile.userName}}
              render={({ value, onChange }) => (
                <UserSearcher
                  label={t('fieldNames.bookedUser')}
                  placeholder={t('fieldNames.bookedUser')}
                  value={value}
                  onChange={(item) => {
                    setValue('bookedUser', item);
                    onChange(item);
                  }}
                  error={errors?.bookedUser?.id}
                  disabled={!isAdmin}
                />
              )}
            />
          </>
        ) : employeeType === EmployeeTypeEnum.External ? (
          <Input
            name='externalUserMail'
            label={t('fieldNames.externalUserEmail')}
            placeholder={t('fieldNames.externalUserEmailPlaceholder')}
            type='text'
            error={errors?.externalUserMail}
            innerRef={register}
            disabled={!isAdmin}
          />
        ) : null}
      </DetailsFormContentAlt>
      <FormFooter>
        <ButtonsContainer>
          <ButtonSecondary
            label={t('shared:generics.cancel')}
            onClick={onClose}
          />
          <ButtonPrimary
            disabled={formState.isSubmitting || !formState.isValid}
            label={t('shared:generics.save')}
            type='submit'
          />
        </ButtonsContainer>
      </FormFooter>
    </form>
  );
};

export { FloorplanNewBooking };

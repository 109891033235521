import { ButtonIcon, GridTemplate, Icon } from '@app/shared/components';
import { UserAvatar } from '@app/shared/components/user-avatar/UserAvatar';
import { useAuthContext } from '@contexts/auth';
import { DailyBooking } from '@domain/bookings';
import { BookingFormModel } from '@domain/bookings/models/shared/BookingFormModel';
import { WorkplaceFormModel } from '@domain/workplaces';
import styled from '@emotion/styled';
import { useCreateBooking } from '@hooks/bookings/useCreateBooking';
import { useCreateBookingForExternalUser } from '@hooks/bookings/useCreateBookingForExternalUser';
import { useUserProfile } from '@stores/userProfileStore';
import { dateIsBeforeToday, removeSeconds } from '@utils/helpers/dateHelper';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCancelBookingDialog } from '../../../../../../../hooks/bookings/useCancelBookingDialog';
import { getBookingsSpace, getSpaceId } from '../FloorplanBookingDetails.utils';
import {
  EmptyLabel,
  IconItem,
  IconItemLabel,
  SectionTitle
} from '../shared/floorplan-bookings-details-layout';
import { FloorplanNewBooking } from './FloorplanNewBookingForm';

const FloorplanBookingDetailBookingListContainer = styled.div`
  ${() => `
    flex: 0.4;
    display: flex;
    min-height: 0;
    flex-direction: column;
  `}
`;

const FloorplanBookingDetailForm = styled.div`
  ${() => `
    flex: 0.6;
    display: flex;
    min-height: 0;
    flex-direction: column;

    form {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  `}
`;

const FloorplanBookingDetailBookingListContent = styled.div`
  ${() => `
    overflow-y: auto;
  `}
`;

interface FloorplanBookingsDetailBookingsProps {
  workplace: WorkplaceFormModel;
  payloadId: string;
  dailyBookings: DailyBooking[];
  selectedDate?: Date;
  onClose: () => void;
}

const FloorplanBookingsDetailBookings = ({
  payloadId,
  dailyBookings,
  workplace,
  selectedDate,
  onClose
}: FloorplanBookingsDetailBookingsProps): ReactElement => {
  const { t } = useTranslation('workplaces');

  const params = useParams<{
    venueId: string;
    buildingId: string;
    floorplanId: string;
  }>();

  const { user } = useAuthContext();
  const isAdmin = user?.rol === 'admin';
  const { userProfile } = useUserProfile();

  const { mutateAsync: createBooking } = useCreateBooking(
    params.venueId,
    params.buildingId,
    params.floorplanId,
    selectedDate!
  );

  const {
    mutateAsync: createBookingForExternalUser
  } = useCreateBookingForExternalUser(
    params.venueId,
    params.buildingId,
    params.floorplanId,
    selectedDate!
  );

  const bookingsSpace = getBookingsSpace(dailyBookings, payloadId);
  const bookingsUsers = dailyBookings.flatMap((d) => d.users);

  const handleOnSubmit = (form: BookingFormModel): Promise<void> => {
    const spaceId = getSpaceId(dailyBookings, payloadId);

    return form.externalUserMail
      ? createBookingForExternalUser({
          userEmail: form.externalUserMail,
          shiftTypeId: form.shiftTypeId,
          spaceId: spaceId,
          date: selectedDate!
        })
      : createBooking({
          userId: form.bookedUser!.id,
          shiftTypeId: form.shiftTypeId,
          spaceId: spaceId,
          date: selectedDate!
        });
  };

  const isPlaceLocked = workplace.lockSite;
  const isPlaceEnabled = workplace.enabled;

  const { handleCancelBooking } = useCancelBookingDialog();

  return (
    <>
      <FloorplanBookingDetailBookingListContainer>
        <SectionTitle>{t('bookingDetails.bookings.bookingList')}</SectionTitle>
        <FloorplanBookingDetailBookingListContent>
          <GridTemplate>
            {bookingsSpace.length === 0 && (
              <EmptyLabel>{t('bookingDetails.bookings.noBookings')}</EmptyLabel>
            )}
            {bookingsSpace.map(({ bookingUserId, bookingId, date, start, end }) => {
              const user = bookingsUsers.find(
                (b) => b.userId === bookingUserId
              );
              const bookingTime = `${removeSeconds(start)} - ${removeSeconds(
                end
              )}`;

              const bookingDate = date?.toDateTimezone();

              return (
                <GridTemplate key={bookingId} columns={3} align='center'>
                  <UserAvatar
                    userName={user?.fullName}
                    userImageUrl={user?.imageUrl}
                    userEmail={user?.email}
                  />
                  <IconItem>
                    <Icon iconName='time' title={bookingTime} />
                    <IconItemLabel>{bookingTime}</IconItemLabel>
                  </IconItem>
                  {isAdmin || (!isAdmin && user?.userId === userProfile.id) ?
                    <ButtonIcon 
                      iconName='trash'
                      iconSize={22}
                      disabled= {bookingDate ? dateIsBeforeToday(bookingDate) : false}
                      onClick={() => { handleCancelBooking(bookingId); }}
                    /> : null
                  }
                </GridTemplate>
              );
            })}
          </GridTemplate>
        </FloorplanBookingDetailBookingListContent>
      </FloorplanBookingDetailBookingListContainer>
      {isPlaceLocked || (!isAdmin && (bookingsSpace.length !== 0 || !isPlaceEnabled)) ? null : (
        <FloorplanBookingDetailForm>
          <SectionTitle>{t('bookingDetails.bookings.new')}</SectionTitle>

          <FloorplanNewBooking
            buildingId={params.buildingId}
            onSubmit={handleOnSubmit}
            onClose={onClose}
            isAdmin={isAdmin}
          />
        </FloorplanBookingDetailForm>
      )}
    </>
  );
};

export { FloorplanBookingsDetailBookings };

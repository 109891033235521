import {
  ButtonBase,
  ButtonIcon,
  ButtonPrimary,
  GridTemplate,
  Icon,
  IconWrapper,
  PanelDock,
  PanelHeader,
  PanelTitle
} from '@app/shared/components';
import { workplacesDefinition } from '@domain/workplaces';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '../floorplan-bookings/floorplan-bookings-details/shared/floorplan-bookings-details-layout';

const Section = styled.section`
  margin-bottom: ${rem(pxToRem(24))};
`;

const LegendColorItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LegendColorItem = styled.div<{ color: string }>(
  (props) => `
  border: 1px solid ${props.color};
  border-radius: 50%;
  background-color: ${getRgbaStrFromHexColor(props.color, 0.3)};
  height: ${rem(pxToRem(40))};
  width: ${rem(pxToRem(40))};
`
);

const LegendColorText = styled.h3(
  ({
    theme: {
      base: {
        fonts: { getHeadlinesConfig, semiBoldFont }
      }
    }
  }) => `
    ${getHeadlinesConfig('XS')};
    font-family: '${semiBoldFont}';
    text-align: center;
    margin-top: ${rem(pxToRem(8))};
`
);

const LegendIconItem = styled.div`
  display: grid;
  gap: ${rem(pxToRem(12))};
  grid-template-columns: auto 1fr 2fr;

  ${IconWrapper} {
    align-items: inherit;
  }
`;

const LegendIconTitle = styled.div(
  ({
    theme: {
      base: {
        fonts: { getHeadlinesConfig, boldFont }
      }
    }
  }) => `
  ${getHeadlinesConfig('XS')};
  font-family: '${boldFont}';
`
);

const LegendIconDescription = styled.div(
  ({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
  color: ${colors.neutral.grayDark};
  ${getTextsConfig('M')};
`
);

const LegendContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

const Footer = styled.div`
  ${ButtonBase} {
    width: 100%;
  }
`;

type FloorplanViewerLegendProps = {
  onClose: () => void;
  isAdmin: boolean;
};

const FloorplanViewerLegend = ({
  onClose,
  isAdmin
}: FloorplanViewerLegendProps): ReactElement => {
  const { t } = useTranslation('workplaces');
  const {
    base: { colors }
  } = useTheme();

  const colorsDefinition = [
    { title: 'legend.colors.free', color: colors.workplaces.free },
    { title: 'legend.colors.reserved', color: isAdmin ?  colors.workplaces.reserved : colors.workplaces.locked },
    { title: 'legend.colors.blocked', color: isAdmin ? colors.workplaces.locked : colors.workplaces.disabled },
    { title: 'legend.colors.disable', color: colors.workplaces.disabled }
  ];

  const iconsDefinition = Object.values(workplacesDefinition)
    .map((def) => (def.subTypes ? Object.values(def.subTypes) : def))
    .flat();

  return (
    <PanelDock>
      <PanelHeader>
        <PanelTitle>{t('legend.title')}</PanelTitle>
        <ButtonIcon
          className='closePanel'
          iconName='cross_small'
          type='button'
          title={t('shared:generics.close')}
          onClick={onClose}
        />
      </PanelHeader>

      <LegendContent>
        <Section>
          <SectionTitle>{t('legend.colors.title')}</SectionTitle>
          <GridTemplate columns={5}>
            {colorsDefinition.map(({ color, title }) => (
              <LegendColorItemWrapper key={title}>
                <LegendColorItem color={color} />
                <LegendColorText>{t(title)}</LegendColorText>
              </LegendColorItemWrapper>
            ))}
          </GridTemplate>
        </Section>

        <Section>
          <SectionTitle>{t('legend.icons.title')}</SectionTitle>
          <LegendIconItem>
            {iconsDefinition.map(({ icon, title, description }) => (
              <Fragment key={title}>
                <Icon
                  iconName={icon}
                  iconSize={24}
                  iconColor={colors.neutral.grayDark}
                />
                <LegendIconTitle>{t(title)}</LegendIconTitle>
                <LegendIconDescription>{t(description)}</LegendIconDescription>
              </Fragment>
            ))}
          </LegendIconItem>
        </Section>
      </LegendContent>

      <Footer>
        <ButtonPrimary label={t('legend.submit')} onClick={onClose} />
      </Footer>
    </PanelDock>
  );
};

export { FloorplanViewerLegend };

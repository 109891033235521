import { USER_QUERY_KEY } from '@domain/user-profile/userProfileKeys';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { AcceptedTermConditionResponse } from '@services/api/apiService';
import { acceptTermCondition } from '@services/term-condition/termConditionService';
import { useQueryClient } from 'react-query';

const useAcceptTermCondition = (): UseMutation<
  AcceptedTermConditionResponse,
  Error,
  void,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<AcceptedTermConditionResponse, Error>(
    acceptTermCondition,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(...USER_QUERY_KEY(''));
      }
    }
  );
};

export { useAcceptTermCondition };
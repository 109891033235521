import { ButtonPrimary } from '@app/shared/components';
import { useAuthContext } from '@contexts/auth';
import styled from '@emotion/styled';
import { useGetPrivacyPolicy } from '@hooks/privacy-policy/useGetPrivacyPolicy';
import { pxToRem, rem } from '@styles/utils/sizes';
import { downloadFile } from '@utils/files-utils';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, useHistory } from 'react-router-dom';

import { toLocaleDateString } from '../../../../utils/helpers/dateHelper';
import { PrivacyPolicyForm } from './privacy-policy-form';
import { CreatePrivacyPolicyFormLocation } from './privacy-policy-form/routes/PrivacyPolicyFormLocation';

const SectionContainer = styled.section`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig, boldFont }
      }
    }
  }) => `
    color: ${colors.primary.default};
    ${getTextsConfig('L')};
    font-family: '${boldFont}';
  `}
`;

const SectionActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(pxToRem(8))};
`;

const Text = styled.p`
  padding-bottom: ${rem(pxToRem(12))};
`;

export const TextContent = styled.span`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    color: ${colors.neutral.grayDark};
  `}
`;

const PrivacyPolicy = (): ReactElement | null => {
  const {
    t,
    i18n: { language }
  } = useTranslation('privacy-policy');

  const { data: privacyPolicy } = useGetPrivacyPolicy();

  const history = useHistory();

  const handleNewPrivacyPolicy = (): void =>
    history.push(CreatePrivacyPolicyFormLocation.toUrl());

  const { user } = useAuthContext();
  const isAdmin = user?.rol === 'admin';

  return (
    <SectionContainer>
      {privacyPolicy ? (
        <>
          <Text>
            {t('main.date')}:{' '}
            <TextContent>
              {toLocaleDateString(privacyPolicy.date, language)}
            </TextContent>
          </Text>
          <SectionActions>
            {isAdmin ?
              <ButtonPrimary
                label={t('update-privacy-policy')}
                onClick={handleNewPrivacyPolicy}
              /> : null
            }
            <ButtonPrimary
              label={t('main.download-button')}
              iconName='download'
              onClick={() =>
                privacyPolicy?.url && downloadFile(privacyPolicy?.url)
              }
            />
          </SectionActions>
        </>
      ) : (
        isAdmin ?
          <ButtonPrimary
            label={t('new-privacy-policy')}
            onClick={handleNewPrivacyPolicy}
          /> : null
      )}
      <Switch>
        {CreatePrivacyPolicyFormLocation.toRoute({
          component: PrivacyPolicyForm
        })}
      </Switch>
    </SectionContainer>
  );
};

export { PrivacyPolicy };

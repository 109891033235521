import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import endOfWeek from 'date-fns/endOfWeek';
import endOfYear from 'date-fns/endOfYear';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';

const getDayLabelsInWeek = (
  date: Date,
  locale: Locale,
  dayLabelFormat: 'narrow' | 'short' | 'long' = 'narrow'
): string[] => {
  const firstDayOfMonth = startOfMonth(date);
  const firstDayOfFirstWeek = startOfWeek(firstDayOfMonth, { locale });
  const lastDayOfFirstWeek = endOfWeek(firstDayOfMonth, { locale });

  return eachDayOfInterval({
    start: firstDayOfFirstWeek,
    end: lastDayOfFirstWeek
  }).map((d) =>
    new Intl.DateTimeFormat(locale.code, { weekday: dayLabelFormat }).format(d)
  );
};

const getMonthsLabels = (
  locale: Locale,
  monthLabelFormat: 'narrow' | 'short' | 'long' = 'short'
): string[] => {
  const date = new Date();
  const firstDayOfYear = startOfYear(date);
  const lastDayOfYear = endOfYear(date);

  return eachMonthOfInterval({
    start: firstDayOfYear,
    end: lastDayOfYear
  }).map((d) =>
    new Intl.DateTimeFormat(locale.code, { month: monthLabelFormat }).format(d)
  );
};

const isValidDate = (date?: Date): boolean =>
  date instanceof Date && !isNaN((date as unknown) as number);

const isSameDate = (date1?: Date, date2?: Date): boolean => {
  return (date1?.getTime() || 0) === (date2?.getTime() || 0);
};

const removeSeconds = (time?: string): string => {
  const split = time?.split(':') || '';
  return split.length > 1 ? `${split[0]}:${split[1]}` : '';
};

const getIntervalSeconds = (time?: string): number | undefined => {
  if (!time) {
    return;
  }
  const actualTime = time.split(':');
  if (actualTime.every((t) => !isNaN(+t))) {
    if (actualTime.length === 2) {
      return +actualTime[0] * 60 * 60 + +actualTime[1] * 60;
    } else if (actualTime.length === 3) {
      return +actualTime[0] * 60 * 60 + +actualTime[1] * 60 + +actualTime[2];
    }
  }
};

const differenceSecondsInterval = (
  time1?: string,
  time2?: string
): number | undefined => {
  const time1Seconds = getIntervalSeconds(time1);
  const time2Seconds = getIntervalSeconds(time2);
  return time1Seconds !== undefined && time2Seconds !== undefined
    ? time2Seconds - time1Seconds
    : undefined;
};

const toLocaleDateString = (date: Date, language: string): string =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toLocaleString(
    language
  );

const dateIsBeforeToday = (date: Date): boolean => {
  const onlyDate = new Date(date.getDate());
  const today = new Date(new Date().toDateTimezone().getDate())
  return onlyDate.isBefore(today);
}

const dateAgo = (n: number): Date => {
  const todayDate = new Date().toDateTimezone();
  todayDate.setDate(todayDate.getDate() - Math.abs(n));
  return todayDate;
};

export {
  getDayLabelsInWeek,
  getMonthsLabels,
  isValidDate,
  isSameDate,
  removeSeconds,
  differenceSecondsInterval,
  toLocaleDateString,
  dateIsBeforeToday,
  dateAgo
};

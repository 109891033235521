import { InputCheckBox } from '@app/shared/components';
import styled from '@emotion/styled';
import { useGetPrivacyPolicy } from '@hooks/privacy-policy/useGetPrivacyPolicy';
import { useGetTermCondition } from '@hooks/term-condition/useGetTermCondition';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CheckContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  margin-top: 0.5rem;
`;

const CheckLabel = styled.label`
     ${({
        theme: {base: { colors }
    }
    }) => `
        color: ${colors.primary.selectable};
        text-decoration: underline;
        text-decoration-color: ${colors.primary.selectable};        
    `}
`;

interface DialogLegalProps {
    setDisabledConfirm: (value: boolean) => void;
}

const DialogLegal = ({
    setDisabledConfirm
}: DialogLegalProps): ReactElement => {
    const [checkTermCondition, setCheckTermCondition] = useState(false);
    const [checkPrivacy, setCheckPrivacy] = useState(false);
    const { t } = useTranslation();

    const { data: termCondition } = useGetTermCondition();
    const { data: privacyPolicy } = useGetPrivacyPolicy();

    useEffect(() => {
        setDisabledConfirm(checkPrivacy && checkTermCondition);
    }, [checkTermCondition, checkPrivacy]); 
    
    return (
        <>  
            <CheckContainer>
                <InputCheckBox 
                    options={[
                    {
                        id: 'termCondition',        
                        onChange: (_event, { isChecked }) => setCheckTermCondition(isChecked)
                    }                        
                    ]}
                />
                <CheckLabel>
                    <a href={termCondition?.url} target='_blank' rel='noopener noreferrer'> 
                        {t('shared:dialog-legal.labelTerms')}                   
                    </a>
                </CheckLabel>                
            </CheckContainer>      
            <CheckContainer>
                <InputCheckBox 
                    options={[
                            {
                            id: 'privacyPolicy',
                            onChange: (_event, { isChecked }) => setCheckPrivacy(isChecked)
                            }
                    ]}
                />        
                <CheckLabel>
                    <a href={privacyPolicy?.url} target='_blank' rel='noopener noreferrer'>
                        {t('shared:dialog-legal.labelPrivacy')} 
                    </a>   
                </CheckLabel>                          
            </CheckContainer>                       
        </>        
    )
};

export default DialogLegal;
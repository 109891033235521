import { BaleaLocation } from '@app/balea';
import { BookingListLocation } from '@app/booking-list';
import { CareManagementLocation } from '@app/care-management';
import { DashboardLocation } from '@app/dashboard';
import { FacilityManagementLocation } from '@app/facility-management';
import { LegalLocation } from '@app/legal';
import { RouteModel } from '@app/shared/routes';
import { UserListLocation } from '@app/user-list';
import React, { lazy } from 'react';

const Balea: React.LazyExoticComponent<React.FC> = lazy(
  () => import('@app/balea/Balea')
);

const BuildingsSection: React.LazyExoticComponent<React.FC> = lazy(
  () => import('@app/facility-management/FacilityManagement')
);

const DashboardSection: React.LazyExoticComponent<React.FC> = lazy(
  () => import('@app/dashboard/Dashboard')
);

const CareManagementSection: React.LazyExoticComponent<React.FC> = lazy(
  () => import('@app/care-management/CareManagement')
);

const BookingListSection: React.LazyExoticComponent<React.FC> = lazy(
  () => import('@app/booking-list/BookingList')
);

const UserListSection: React.LazyExoticComponent<React.FC> = lazy(
  () => import('@app/user-list/UserList')
);

const LegalSection: React.LazyExoticComponent<React.FC> = lazy(
  () => import('@app/legal/Legal')
);

const sectionRoutes: RouteModel[] = [
  {
    location: BaleaLocation,
    exact: false,
    component: Balea,
    isPrivate: true
  },
  {
    location: DashboardLocation,
    exact: false,
    component: DashboardSection,
    isPrivate: true
  },
  {
    location: FacilityManagementLocation,
    exact: false,
    component: BuildingsSection,
    isPrivate: true
  },
  {
    location: CareManagementLocation,
    exact: false,
    component: CareManagementSection,
    isPrivate: true,
    rol: 'admin'
  },
  {
    location: BookingListLocation,
    exact: false,
    component: BookingListSection,
    isPrivate: true
  },
  {
    location: UserListLocation,
    exact: false,
    component: UserListSection,
    isPrivate: true,
    rol: 'admin'
  },
  {
    location: LegalLocation,
    exact: false,
    component: LegalSection,
    isPrivate: true
  }
];

export { sectionRoutes };

import { configuration } from '@configuration';
import { PrivacyPolicy } from '@domain/privacy-policy';
import { apiService } from '@services/api';
import { AcceptedPrivacyPolicyResponse, FileParameter } from '@services/api/apiService';

const createPrivacyPolicy = async (
  privacyPolicyFile: FileParameter
): Promise<void> => {
  await apiService.privacyPolicy_Create(privacyPolicyFile);
};

const getPrivacyPolicy = async (): Promise<PrivacyPolicy | undefined> => {
  const { organizationId } = configuration;

  const result = await apiService.privacyPolicy_Get(organizationId as number);
  return result && result.date ? result : undefined;
};

const acceptPrivacyPolicy = (): Promise<
  AcceptedPrivacyPolicyResponse
> => {
  return apiService.privacyPolicy_Accept();
};

export { createPrivacyPolicy, getPrivacyPolicy, acceptPrivacyPolicy };

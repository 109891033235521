import { USER_QUERY_KEY } from '@domain/user-profile/userProfileKeys';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { AcceptedPrivacyPolicyResponse } from '@services/api/apiService';
import { acceptPrivacyPolicy } from '@services/privacy-policy/privacyPolicyService';
import { useQueryClient } from 'react-query';

const useAcceptPrivacyPolicy = (): UseMutation<
  AcceptedPrivacyPolicyResponse,
  Error,
  void,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<AcceptedPrivacyPolicyResponse, Error>(
    acceptPrivacyPolicy,
    {
      onSuccess: () => {
        queryClient.removeQueries(...USER_QUERY_KEY(''));
      }
    }
  );
};

export { useAcceptPrivacyPolicy };
import { Table, TableData, TableRequestModel } from '@app/shared/components';
import { useAuthContext } from '@contexts/auth';
import { BookingSearch } from '@domain/bookings';
import { useGetAllBookings } from '@hooks/bookings';
import { useCancelBookingDialog } from '@hooks/bookings/useCancelBookingDialog';
import { useUserProfile } from '@stores/userProfileStore';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { getBookingListTableColumns } from './use-get-booking-list-table-columns/BookingListTableColumns';

interface BookingListTableProps {
  buildingName: string;
  bookingDate: Date;
}

const BookingListTable = ({
  buildingName,
  bookingDate
}: BookingListTableProps): ReactElement => {
  const [bookingsRequest, setBookingsRequest] = useState<
    TableRequestModel<BookingSearch> | undefined
  >();

  const {
    data: bookings,
    isLoading: isLoadingBookings,
    isFetching,
    isSuccess: isGetBookingSuccess
  } = useGetAllBookings(bookingsRequest);

  const { handleCancelBooking } = useCancelBookingDialog();

  const columns: TableData<BookingSearch> = useMemo(
    () => getBookingListTableColumns(handleCancelBooking),
    []
  );

  const { userProfile } = useUserProfile();
  const { user } = useAuthContext();
  const isAdmin = user?.rol === 'admin';
  
  const handleOnFetchData = (model: TableRequestModel<BookingSearch>): void => {
    if(!isAdmin){
      model.filters?.push({id: 'userName', value: [userProfile.userName], operator: 'eq'})
    }

    setBookingsRequest({
      ...model,
      filters: [
        ...(model.filters?.filter(
          (x) => x.id !== 'bookingDate' && x.id !== 'buildingName'
        ) ?? []),
        {
          id: 'bookingDate',
          value: [bookingDate],
          operator: 'eq'
        },
        {
          id: 'buildingName',
          value: [buildingName],
          operator: 'eq'
        }
      ]
    });
  };

  useEffect(() => {
    if (bookingsRequest) {
      handleOnFetchData(bookingsRequest);
    }
  }, [buildingName, bookingDate]);

  const isLoading = !isGetBookingSuccess || isLoadingBookings || isFetching;

  return (
    <Table<BookingSearch>
      isPaginable
      isSortable
      isFilterable
      data={bookings?.data}
      columns={columns}
      isLoading={isLoading}
      count={bookings?.count}
      onFetchData={handleOnFetchData}
    />
  );
};

export { BookingListTable };

import { BOOKINGS_LIST_QUERY_KEY, BOOKINGS_QUERY_KEY, DAILY_BOOKING_QUERY_KEY } from '@domain/bookings';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { cancelBooking } from '@services/bookings/bookingsService';
import { useQueryClient } from 'react-query';

const useCancelBooking = (): UseMutation<void, Error, string, unknown> => {
    const queryClient = useQueryClient();
  
    return useMutationWrapper<void, Error, string>(
      (bookingId) => cancelBooking(bookingId),
      {
        onSuccess: () => {
            queryClient.invalidateQueries(BOOKINGS_QUERY_KEY);
            queryClient.invalidateQueries(DAILY_BOOKING_QUERY_KEY);
            queryClient.invalidateQueries(BOOKINGS_LIST_QUERY_KEY);
        }
      }
    );
  };
  
  export { useCancelBooking };
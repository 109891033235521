import { CellProps, TableData } from '@app/shared/components';
import {
  ButtonIconCell,
    UserCell
} from '@app/shared/components/table/cells';
import { BookingSearch } from '@domain/bookings';
import i18n from '@services/i18n';
import { dateIsBeforeToday } from '@utils/helpers/dateHelper';
import React from 'react';


export const getBookingListTableColumns = (
  cancelBooking: (bookingId: string) => void
): TableData<BookingSearch> => [
  {
    id: 'start',
    Header: i18n.t('bookings:list.table.startHour') as string,
    accessor: 'start'
  },
  {
    id: 'end',
    Header: i18n.t('bookings:list.table.endHour') as string,
    accessor: 'end'
  },
  {
    id: 'userName',
    Header: i18n.t('bookings:list.table.userName') as string,
    accessor: ({ userName, userImageUrl }) => ({
      userName,
      userImageUrl
    }),
    width: 250,
    Cell: UserCell
  },
  {
    id: 'spaceTypeName',
    Header: i18n.t('bookings:list.table.type') as string,
    accessor: 'spaceTypeName'
  },
  {
    id: 'floorName',
    Header: i18n.t('bookings:list.table.zone') as string,
    accessor: 'floorName'
  },
  {
    id: 'spaceName',
    Header: i18n.t('bookings:list.table.nameSpace') as string,
    accessor: 'spaceName'
  },
  {
    id: 'cancel-booking',
    accessor: ({ bookingDate, bookingId }) => ({ bookingDate, bookingId }),
    disableFilters: true,
    width: 25,
    Cell: ({
      value: { bookingDate, bookingId }
    }: CellProps<BookingSearch, Pick<BookingSearch, 'bookingDate' | 'bookingId'>>) => (
      <ButtonIconCell
        iconName='trash'
        disabled={dateIsBeforeToday(bookingDate)}
        onClick={() => {
          cancelBooking(bookingId);
        } } />
    )
  }
];

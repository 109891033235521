import { useDialog } from "@app/shared/components/dialog";
import { notifySuccess } from "@app/shared/notifications";
import { useTheme } from "@emotion/react";
import { useCancelBooking } from "@hooks/bookings/useCancelBooking";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface CancelBookingDialogProps {
    handleCancelBooking: (bookingId: string) => void;
}

const useCancelBookingDialog = (): CancelBookingDialogProps => {    
      const { t } = useTranslation('workplaces');
      const { base: { colors } } = useTheme();
      
      const {
        mutateAsync: cancelBooking,
        isSuccess: isCancelBookingSuccess
      } = useCancelBooking();
    
      const { showDialog } = useDialog();
    
      const handleCancelBooking = (bookingId: string): void => {
        showDialog({
          open: true,
          title: t('cancel-prompt.title'),
          subtitle: t('cancel-prompt.subtitle'),
          message: t('cancel-prompt.body'),
          headerIcon: 'trash',
          headerColor: colors.system.error,
          btnPrimaryText: t('shared:confirmation.yes'),
          btnSecondaryText: t('shared:confirmation.no'),
          onConfirm: {
            callback: () => cancelBooking(bookingId)     
          }
        });
      };
      
      useEffect(() => {
        if (isCancelBookingSuccess) {
          notifySuccess(t('shared:toast.operation-success'));
        }
      }, [isCancelBookingSuccess]);

      return { handleCancelBooking }
}

export { useCancelBookingDialog }
import { getRgbaStrFromHexColor } from '../../utils/color';
import { createFont } from '../../utils/fonts';
import { pxToRem, rem } from '../../utils/sizes';
import { Colors, Theme } from '../models';

const theme: Theme = {
  base: {
    colors: {
      primary: {
        light: '#66E1E4',
        default: '#22252A',
        dark: '#FD7E73',
        darkest: '#22252A',
        selectable: '#FD7E73'
      },
      secondary: {
        first: '#FFA502',
        second: '#FF4456',
        secondDark: '#DD2234',
        third: '#00A8A1'
      },
      neutral: {
        dark: '#222222',
        grayDark: '#868E96',
        gray: '#CCCCCC',
        grayAccent: '#E7E7E7',
        grayMedium: '#EDEDED',
        grayLight: '#F5F5F5',
        fullLight: '#ffffff',
        transparent: '#00000000'
      },
      system: {
        error: '#FF5555',
        warning: '#FF8742',
        warning2: '#FCDC3E',
        success: '#27C15B',
        info: '#88B8EE'
      },
      layout: {
        bgApp: '#F0F3F5'
      },
      workplaces: {
        free: '#27C15B',
        reserved: '#FFA502',
        locked: '#FF5555',
        disabled: '#C9D0DB',
        selectedByUser: '#202D84',
        userLocked: '#C9D0DB',
        userReserved: '#FF5555'
      },
      spinner: '#FD7E73'
    },
    fonts: createFont(
      'Montserrat Regular',
      'Montserrat Medium',
      'Montserrat SemiBold',
      'Montserrat Bold',
      {
        headline: {
          XXXL: 56,
          XXL: 38,
          XL: 30,
          L: 24,
          M: 20,
          S: 16,
          XS: 14
        },
        lineHeightHeadline: {
          XXXL: 70,
          XXL: 60,
          XL: 48,
          L: 38,
          M: 32,
          S: 25,
          XS: 22
        },
        text: {
          L: 16,
          M: 14,
          S: 12,
          XS: 10
        },
        lineHeightText: {
          L: 25,
          M: 22,
          S: 20,
          XS: 18
        }
      }
    ),
    zindex: {
      loader: 9010,
      tooltip: 9004,
      modalContent: 9003,
      modalOverlay: 9002,
      super: 9000,
      above: 900,
      XS: 5,
      default: 1,
      S: 10
    },
    shadow: {
      base: (colors: Colors): string =>
        `0 ${rem(pxToRem(4))} ${rem(pxToRem(12))} ${rem(
          pxToRem(-2)
        )} ${getRgbaStrFromHexColor(colors.neutral.dark, 0.18)}`,
      modal: (colors: Colors): string =>
        `0 ${rem(pxToRem(4))} ${rem(pxToRem(12))} 0 ${getRgbaStrFromHexColor(
          colors.primary.darkest,
          0.15
        )}`,
      default: (colors: Colors): string =>
        `0 ${rem(pxToRem(4))} ${rem(pxToRem(8))} 0 ${getRgbaStrFromHexColor(
          colors.neutral.grayDark,
          0.5
        )}`,
      card: (colors: Colors): string =>
        `0 0 ${rem(pxToRem(2))} ${getRgbaStrFromHexColor(
          colors.neutral.grayDark,
          0.4
        )}`,
      cardHover: (colors: Colors): string =>
        `0 ${rem(pxToRem(4))} ${rem(pxToRem(8))} 0 ${getRgbaStrFromHexColor(
          colors.neutral.grayDark,
          0.65
        )}`
    },
    radius: {
      circle: '50%',
      rounded: '50px'
    },
    layout: {
      header: {
        height: rem(pxToRem(72))
      }
    },
    scrollbar: {
      radius: 4,
      size: 6,
      bgScrollbar: (colors: Colors): string => `${colors.neutral.grayLight}`,
      bgScrollbarTrack: (colors: Colors): string =>
        `${colors.neutral.grayLight}`,
      bgScrollbarThumb: (colors: Colors): string => `${colors.neutral.grayDark}`
    },
    grid: {
      card: {
        width: 326,
        height: 379
      }
    },
    modal: {
      large: rem(pxToRem(984))
    }
  }
};

export default theme;

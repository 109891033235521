import styled from '@emotion/styled';
import React from 'react';

import { NavbarItem } from './navbar-item/NavbarItem';

const NavbarWrapper = styled.nav`
  width: 100%;
`;

export interface NavbarItemData {
  linkTo: string;
  iconName: string;
  label: string;
  rol?: string;
}

interface NavbarItemProps {
  links: NavbarItemData[];
  rol?: string;
}

const Navbar: React.FC<NavbarItemProps> = ({ links, rol }: NavbarItemProps) => {
  return (
    <NavbarWrapper>
      {links.map((link, index) => (!link.rol || link.rol === rol ? (
        <NavbarItem
          key={index}
          linkTo={link.linkTo}
          iconName={link.iconName}
          label={link.label}
        />
      ) : null
    ))}
    </NavbarWrapper>
  );
};

export { Navbar };

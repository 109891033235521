import { ButtonIcon } from '@app/shared/components/button-icon';
import styled from '@emotion/styled';
import React, { ReactElement } from 'react';

import { ButtonBase } from '../../../button/Button';

const ButtonWrapper = styled.div`
  ${ButtonBase} {
    margin: 0 auto;
  }
`;

type ButtonIconCellProps = {
    iconName: string;
    iconSize?: number;
    iconColor?: string;
    btnStyle?: string;
    onClick?: () => void;
    disabled?: boolean;
};

const ButtonIconCell = ({
  onClick,
  disabled = false,
  iconName = '',
  iconSize = 24,
  iconColor = ''
}: ButtonIconCellProps): ReactElement => (
  <ButtonWrapper>
    <ButtonIcon
        iconName={iconName}
        iconSize={iconSize}
        iconColor={iconColor}
        onClick={onClick}
        disabled={disabled}        
    />
  </ButtonWrapper>
);

export { ButtonIconCell };
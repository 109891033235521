import DialogLegal from '@app/legal/dialog/DialogLegal';
import { useDialog } from '@app/shared/components/dialog';
import { useAuthContext, useAuthContextActions } from '@contexts/auth';
import { useTheme } from '@emotion/react';
import { useAcceptPrivacyPolicy } from '@hooks/privacy-policy/useAcceptPrivacyPolicy';
import { useAcceptTermCondition } from '@hooks/term-condition/useAcceptTermCondition';
import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = { 
  rol?: 'admin'; 
}

const PrivateRoute = ({ children, rol }: PropsWithChildren<Props>): ReactElement | null => {
  const { user, isLoading } = useAuthContext();
  const { goLoginPage, goLogoutPage } = useAuthContextActions();
  const [disabled, setDisabled] = useState(true);
  
  const { mutateAsync: acceptPrivacyPolicy } = useAcceptPrivacyPolicy();
  const { mutateAsync: acceptTermCondition } = useAcceptTermCondition();

  const {
      base: { colors }
    } = useTheme();
  const { showDialog } = useDialog();
  const { t } = useTranslation();

  const legalBasesDialog = (): void => {
    showDialog({
    open: true,
    title: t('shared:dialog-legal.title'),
    subtitle: t('shared:dialog-legal.subtitle'),
    headerIcon: 'warning',
    headerColor: colors.system.error,
    disabledPrimaryButton: disabled,    
    message: <DialogLegal setDisabledConfirm={setDisabled} />,
    btnPrimaryText: t('shared:generics.accept'),
    onConfirm: {
      callback: () => {       
        acceptPrivacyPolicy();
        acceptTermCondition();
      }
    },
    onCancel: {
      callback: () => {       
        goLogoutPage();
      }
    }
  })};

  useEffect(() => {
    if (!isLoading && !user) {
      goLoginPage();
    }
    if(user && 
      (!rol || (user && user.rol === rol)) && 
      !user?.acceptedLegal){
      legalBasesDialog();
    }
  }, [isLoading, user, disabled]); 

  return !rol || (user && user.rol === rol) ? <>{children}</> : null;
};

export { PrivateRoute };

import { TableDataResult, TableRequestModel } from '@app/shared/components';
import { BookingSearch, DailyBooking } from '@domain/bookings';
import { CreateBookingModel, CreateBookingModelForExternalUser } from '@domain/bookings/models/shared/BookingFormModel';
import { apiService } from '@services/api';
import {
  CreateBookingForExternalUserModel,
  CreateBookingForGivenUserModel,
  SearchBookingModel
} from '@services/api/apiService';

const getDailyBookings = async (
  id: string,
  startDate: Date,
  endDate?: Date
): Promise<DailyBooking[]> => {
  return await apiService.booking_GetBookingInfoBy(
    id,
    null,
    startDate,
    endDate ?? startDate
  );
};

const getBookings = async (
  model: TableRequestModel<BookingSearch>
): Promise<TableDataResult<BookingSearch>> => 
  await apiService.booking_Search(SearchBookingModel.fromJS(model));

const createBooking = async (model: CreateBookingModel): Promise<void> => {
  await apiService.booking_Create(CreateBookingForGivenUserModel.fromJS(model));
};

const createBookingForExternalUser = async (model: CreateBookingModelForExternalUser): Promise<void> => {
  await apiService.booking_CreateForExternalUser(CreateBookingForExternalUserModel.fromJS(model));
};

const cancelBooking = async (bookingId: string): Promise<void> => {
  await apiService.booking_Cancel(bookingId);
};

export { getDailyBookings, getBookings, createBooking, createBookingForExternalUser, cancelBooking };

import { UserResponse } from '@domain/user-profile';
import { useDebounce } from '@hooks/useDebounce';
import { useSearchActiveProfiles } from '@hooks/user-profile/useSearchUsers';
import { useUpdateEffect } from '@hooks/useUpdateEffect';
import React, { ReactElement, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';

import { Select } from './Select';

type UserSearcherProps = {
  label?: string;
  placeholder?: string;
  value?: UserResponse;
  disabled?: boolean;
  error?: FieldError;
  onChange?: (user?: UserResponse | null) => void;
};

const UserSearcher = ({
  label,
  placeholder,
  value,
  disabled=false,
  error,
  onChange
}: UserSearcherProps): ReactElement => {
  const [items, setItems] = useState<UserResponse[]>([]);
  const [name, setName] = useState<string | undefined>();
  const [selectedItem, setSelectedItem] = useState<
    UserResponse | null | undefined
  >(value);

  const { data, refetch, isFetching, isSuccess } = useSearchActiveProfiles(
    name!,
    false
  );

  useEffect(() => {
    if (value?.name !== '') {
      setName(value?.name);
    }
  }, []);

  useEffect(() => {
    if (isFetching) {
      setItems([]);
    }
  }, [isFetching]);

  useEffect(() => {
    if (isSuccess) {
      if(value?.name !== '' && disabled){
        setSelectedItem(data?.[0]);
        onChange?.(data?.[0]);
      }else{
        setItems(data ?? []);
      }      
    }
  }, [data, isSuccess]);

  useUpdateEffect(() => {
    setSelectedItem(value);
  }, [value]);

  useDebounce(
    () => {
      if (name) {
        refetch();
      }
    },
    250,
    [name]
  );

  return (
    <Select
      label={label}
      items={items}
      item={selectedItem}
      getItemLabel={(item) => item?.name || ''}
      placeholder={placeholder}
      getItemValue={(item) => item?.id}
      onInputValueChange={(inputValue) => {
        setName(inputValue);
        setItems([]);
      }}
      searchable={true}
      isLoading={isFetching}
      onChange={(item) => {
        setName(item?.name);
        setSelectedItem(item);
        onChange?.(item);
      }}
      error={error}
      disabled={disabled}
    />
  );
};

export { UserSearcher };

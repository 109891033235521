import { BuildingsLocation } from '@app/facility-management/buildings';
import {
  ButtonPrimary,
  PageTitle,
  PageToolbar,
  PageToolbarActions,
  PageToolbarHeader
} from '@app/shared/components';
import {
  Breadcrumb,
  BreadcrumbItems
} from '@app/shared/components/breadcrumb/Breadcrumb';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CreateFloorplansFormLocation } from '../floorplans-form/routes/floorplansFormLocation';
import Filters from './filters/Filters';

interface FloorplansToolbarProps {
  venueId: string;
  venueName: string;
  buildingId: string;
  buildingName: string;
  canDoActions: boolean;
}

const FloorplansToolbar = ({
  venueId,
  venueName,
  buildingId,
  buildingName,
  canDoActions
}: FloorplansToolbarProps): ReactElement => {
  const { t } = useTranslation('floorplans');

  const history = useHistory();

  const handleNewFloorplan = (): void => {
    history.push(CreateFloorplansFormLocation.toUrl({ venueId, buildingId }));
  };

  const brItems: BreadcrumbItems[] = [
    {
      label: venueName,
      linkTo: BuildingsLocation.toUrl({ venueId })
    },
    {
      label: buildingName
    }
  ];

  return (
    <PageToolbar>
      <PageTitle label={t(`shared:routes.facilities`)} />
      <PageToolbarHeader>
        <Breadcrumb items={brItems} />
      </PageToolbarHeader>
      {
      canDoActions ? 
        <PageToolbarActions>
          <ButtonPrimary label={t('newFloorplan')} onClick={handleNewFloorplan} />
          <Filters />
        </PageToolbarActions>
      : null
      }
    </PageToolbar>
  );
};

export default FloorplansToolbar;

import { LoginLocation, LoginState, LogoutLocation } from '@modules/app/auth';
import { AuthService } from '@services/auth';
import { getUserProfile } from '@services/user-profile/userProfileService';
import { User } from 'oidc-client';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { useHistory } from 'react-router-dom';

import {
  AuthContext,
  AuthContextActions,
  AuthContextState
} from './authContext';
import { AuthProviderReducer } from './authProviderReducer';

type AuthProviderProps = {
  authService: AuthService;
  onError: (error: Error) => void;
  onUserLoaded: (user: User) => void;
};

export type AuthProviderState = AuthContextState;

const AuthProvider = ({
  children,
  authService,
  onError: notifyError,
  onUserLoaded: notifyUserLoaded
}: PropsWithChildren<AuthProviderProps>): ReactElement => {
  const history = useHistory();

  const [state, dispatch] = useReducer(AuthProviderReducer, {
    isLoading: true
  });

  useEffect(() => {
    if (!state.isLoading && state.user) {
      const redirect = sessionStorage.getItem('auth:redirect');
      if (redirect) {
        sessionStorage.removeItem('auth:redirect');
        history.push(redirect);
      }
    }
  }, [state.user, state.isLoading]);

  const goLoginPage = useCallback(async (): Promise<void> => {
    history.push(LoginLocation.toUrl(), {
      previousPath: history.location.pathname
    } as LoginState);
  }, []);

  const goLogoutPage = useCallback(() => {
    history.push(LogoutLocation.toUrl());
  }, []);

  const login = useCallback(
    async (redirectUri?: string): Promise<void> => {
      dispatch({ type: 'ON_LOADING' });
      sessionStorage.setItem(
        'auth:redirect',
        redirectUri ? redirectUri : window.location.pathname
      );
      await authService.authenticateUser();
    },
    [authService]
  );

  const completeLogin = useCallback(async (): Promise<void> => {
    await authService.authenticateUserCallback();
  }, [authService]);

  const completeSilentRenewToken = useCallback(async (): Promise<void> => {
    await authService.signInSilentCallback();
  }, [authService]);

  const logout = useCallback(async (): Promise<void> => {
    await authService.signOutUser();
  }, [authService]);

  const onUserLoaded = useCallback(async (user: User): Promise<void> => {    
    const userProfile = await getUserProfile();    
    notifyUserLoaded(user);
    const acceptedLegal = (userProfile.acceptedPrivacyDate !== undefined && userProfile.acceptedTermConditionDate !== undefined) || false;
    dispatch({ type: 'LOAD_USER', user, rol: userProfile.rol, acceptedLegal: acceptedLegal });
  }, []);

  const onUserUnloaded = useCallback(async (): Promise<void> => {
    dispatch({ type: 'UNLOAD_USER' });
    await authService.revokeAccessToken();
  }, [authService]);

  const onError = useCallback((error: Error): void => {
    dispatch({ type: 'LOAD_COMPLETED' });
    notifyError(error);
  }, []);

  const onAccessTokenExpiring = useCallback(async (): Promise<void> => {
    try {
      await authService.signInSilent();
    } catch (error) {
      notifyError(error);
    }
  }, [authService]);

  const onAccessTokenExpired = useCallback(async (): Promise<void> => {
    dispatch({ type: 'UNLOAD_USER' });
  }, [authService]);

  useEffect(() => {
    authService.registerEvents(
      onUserLoaded,
      onUserUnloaded,
      onError,
      onAccessTokenExpiring,
      onAccessTokenExpired
    );
    return () =>
      authService.unregisterEvents(
        onUserLoaded,
        onUserUnloaded,
        onError,
        onAccessTokenExpiring,
        onAccessTokenExpired
      );
  }, [
    authService,
    onUserLoaded,
    onUserUnloaded,
    onError,
    onAccessTokenExpiring,
    onAccessTokenExpired
  ]);

  const initialize = useCallback(async (): Promise<void> => {
    dispatch({ type: 'ON_LOADING' });
    if (!state.user) {
      const userLoaded = await authService.tryLoadUser();
      if (!userLoaded) {
        dispatch({ type: 'LOAD_COMPLETED' });
      }
    } else {
      dispatch({ type: 'LOAD_COMPLETED' });
    }
  }, [authService, state.user]);

  const signinSilent = useCallback(async () => {
    await authService.signInSilent();
  }, [authService]);

  const actionsValue = useMemo(
    () => ({
      goLoginPage,
      login,
      goLogoutPage,
      logout,
      completeLogin,
      completeSilentRenewToken,
      signinSilent,
      initialize
    }),
    [
      goLoginPage,
      login,
      goLogoutPage,
      logout,
      completeLogin,
      completeSilentRenewToken,
      signinSilent,
      initialize
    ]
  );

  return (
    <AuthContextActions.Provider value={actionsValue}>
      <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
    </AuthContextActions.Provider>
  );
};

export { AuthProvider };
